<template>
  <div>
    <v-card v-for="item in items" :key="item.id" class="mt-4 card">
      <v-row>
        <v-col cols="4" class="pl-0">
          <v-list-item-avatar size="90" tile class="mr-1" color="white">
            <v-img :src="item.image"/>
          </v-list-item-avatar>
        </v-col>
        <v-col cols="8" class="my-auto">
          <div class="mt-2">
            <span class="medium-font">نام محصول:</span>
            <span class="medium-font mr-1" v-text="item.title.slice(0,50)"><span v-if="item.title > item.title.slice(0,50)" >...</span></span>
          </div>
          <div>
            <span v-if="item.is_bulk != 1" class="medium-font">تعداد:</span>
            <span v-if="item.is_bulk == 1" class="medium-font">مقدار:</span>
            <span class="medium-font mr-1" v-text="item.count"></span>
          </div>
          <div>
            <span class="medium-font">قیمت واحد:</span>
            <span class="medium-font mr-1" v-text="item.price"></span>
          </div>
          <div>
            <span class="medium-font">مبلغ:</span>
            <span class="font" v-text="item.toll_price"></span>
          </div>
        </v-col> 
      </v-row>
      <v-divider></v-divider>
      <v-card-actions>
        <span class="medium-font mr-2">تامین کننده:</span>
        <v-spacer></v-spacer>
        <span class="medium-font mr-auto" v-text="item.org_title"></span>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      items: state => state.factors.cargoItems
    })
  }
}
</script>